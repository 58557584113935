import { Guid } from "guid-typescript";

export default class BopOperation {
  public id: string;
  public code: string;
  public operationId: string | null;
  public operation: any;
  public quantity: number;
  public operationPoints: number;
  public totalPoints: number;
  public operationFamilyId: string | undefined;
  /**
   * Used when loading or sending properties to the server
   */
  public coefficientSelectedValues: any;

  /**
   * used ony to calculate the values not sent in server requests
   */
  public coefficients: any;

  constructor() {
    this.id = Guid.create().toString();
    this.code = "";
    this.operationId = null;
    this.operation = {};
    this.quantity = 1;
    this.operationPoints = 0;
    this.totalPoints = 0;
    // this.coefficientSelectedValues = {};
    this.coefficients = {};
  }
}
