var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isFullyLoaded),expression:"isFullyLoaded"}],staticClass:"animated fadeIn"},[(
      _vm.selectedBopModel &&
        _vm.selectedBopModel.haveOperationFamilyFilter &&
        _vm.isOperationPrincipal
    )?_c('OperationFamilyFilterComponent',{attrs:{"isFirstLevelDisabled":_vm.isFirstLevelDisabled,"rootFamilyId":_vm.rootFamilyId},on:{"operationPreAdd":_vm.onOperationPreAdd,"operations-family-changed":_vm.onOperationFamilyChanged}}):_vm._e(),(
      _vm.operationGroupCoefficientFields &&
        _vm.operationGroupCoefficientFields.length
    )?_c('b-card',{staticClass:"default mt-2"},[_c('b-row',_vm._l((_vm.operationGroupCoefficientFields),function(field){return _c('b-col',{key:((_vm.operationGroup.id) + "_" + (field.id))},[(field.options)?_c('b-form-group',{key:((_vm.operationGroup.id) + "_" + (field.id)),attrs:{"label":field.name,"label-for":("field-" + (field.id))}},[_c('b-form-select',{attrs:{"disabled":!_vm.isEditable,"options":field.options,"text-field":"value","value-field":"key.id"},on:{"change":function($event){return _vm.operationGroupValueChanged(field)}},model:{value:(_vm.operationGroup.selectedGroupCoefficients[field.coeffKey]),callback:function ($$v) {_vm.$set(_vm.operationGroup.selectedGroupCoefficients, field.coeffKey, $$v)},expression:"operationGroup.selectedGroupCoefficients[field.coeffKey]"}})],1):_c('b-form-group',{attrs:{"label":field.name,"label-for":("field-" + (field.id))}},[_c('b-form-input',{attrs:{"id":("field-" + (field.id)),"disabled":!_vm.isEditable,"required":"","type":"number"},on:{"blur":function($event){return _vm.operationGroupValueChanged(field)}},model:{value:(_vm.operationGroup.selectedGroupCoefficients[field.coeffKey]),callback:function ($$v) {_vm.$set(_vm.operationGroup.selectedGroupCoefficients, field.coeffKey, $$v)},expression:"operationGroup.selectedGroupCoefficients[field.coeffKey]"}})],1)],1)}),1)],1):_vm._e(),_c('b-card',{staticClass:"default mt-2"},[(
        this.columns && (!_vm.isPreGeneratedOperationsBop || _vm.operations.length)
      )?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('BopSetupTable',{key:_vm.refreshKey,ref:"table",attrs:{"addLabel":_vm.isEditable && !_vm.isBopElectriciteInstrum && !_vm.isBopEchafaudage
              ? _vm.$t('bopSetup.operation.addBlankOperation')
              : '',"autoCompleteData":_vm.autoCompleteOperations,"autoCompleteSerializer":_vm.isBopEchafaudage ? null : function (s) { return ((s.key.code) + " " + (s.key.name)); },"columns":_vm.data.columns,"isAddDisabled":_vm.isAddDisabled,"isReadOnly":!_vm.isEditable,"modifiedData":_vm.modifiedOperations,"operations":_vm.operations,"trackBy":"id"},on:{"totalPointsChanged":_vm.onTotalPointsChanged,"add-data":_vm.add,"remove-data":_vm.remove,"autocomplete-add-data":_vm.addTheFoundOperation,"table-mounted":_vm.onTableMounted}})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }